/*
 * @Descripttion: 全局路由配置
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-01-19 09:51:34
 */
import { RoutesList as RoutesCN } from './cn/index'
import { RoutesList as RoutesSG } from './sg/index'
import { RoutesList as RoutesMY } from './my/index'
import { RoutesList as RoutesTH } from './th/index'
import { RoutesList as RoutesID } from './id/index'
import { RoutesList as RoutesPH } from './ph/index'
import { RoutesList as RoutesVN } from './vn/index'
import { RoutesList as RoutesJP } from './jp/index'
import { RoutesList as RoutesAU } from './au/index'

import { pathObj } from '@/utils/utils'
import { specialRouterSite } from '@/utils/const'
// 网站导航路由---依据页面访问路径
// 页面目录可依据此来进行
// 页面路由状态可依赖此进行逻辑判断
// 路由层级包括(site、lang、module、detail)
const siteRouter = {
  cn: RoutesCN,
  sg: RoutesSG,
  my: RoutesMY,
  th: RoutesTH,
  id: RoutesID,
  ph: RoutesPH,
  vn: RoutesVN,
  jp: RoutesJP,
  au: RoutesAU,
}

// console.log('RoutesJP--', RoutesJP);

export const RoutesListFn = (site) => siteRouter[site]

// console.log('RoutesListFn--', RoutesListFn);

export const RouterSupportRequire = (site) => {
  // 如果是特殊站点
  const params = specialRouterSite.includes(site)
    ? {}
    : require(`@/router/${site}/support`)
  return { ...params, ...{ default: {} } }
}

export const BaseRoutesList = (site) => [
  {
    path: '',
    name: 'site',
    children: [
      {
        path: '',
        name: 'lang',
        children: RoutesListFn(site),
      },
    ],
  },
]

export const routerPathEnum = (site) => pathObj(BaseRoutesList(site))
