
import { mapState } from 'vuex'
import data from './data'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import PhoneEmailForm from '@/components/phoneEmail/index.vue'
import Result from '@/modules/templates/default/invoice/result.vue'
import Tips from '@/modules/templates/default/invoice/tip.vue'
export default {
  components: {
    SupportNav,
    SupportTop,
    Result,
    Tips,
    PhoneEmailForm,
  },
  data() {
    return {
      data,
      showError: false,
      resultObj: {},
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
    }),
    showResult() {
      return (
        this.resultObj?.fcInvoiceItem?.feeItems?.length ||
        this.resultObj?.awbItem?.length > 0 ||
        this.resultObj?.clearanceDocumentItem?.length > 0 ||
        this.resultObj?.dutyAndCommercialInvoiceItem?.length > 0
      )
    },
  },
  created() {},
  methods: {
    handleSubmit(params) {
      const { waybillNo, ...others } = params
      if (['mobile', 'email'].includes(others.sendType)) {
        // 发送场景  1: 短信  2: 邮箱
        const sendType = others.sendType === 'mobile' ? '1' : '2'
        this.getSlideMyInvoice(waybillNo, { ...others, sendType })
      } else {
        // 座机单独调用一个接口
        const realParams = {
          ...others,
          mobileNoLast4: others.mobileNoLast4,
          app: 'invoice',
        }
        delete realParams.sendType
        this.telVerifyQuery(waybillNo, realParams)
      }
    },
    // 邮箱、短信验证
    getSlideMyInvoice(waybillNo, others) {
      this.$API.support
        .getSlideMyInvoiceApi(waybillNo, others)
        .then((res) => {
          if (res) {
            this.resultObj = res
            if (!this.showResult) {
              this.showError = true
            } else {
              this.showError = false
            }
          }
        })
        .finally(() => {
          const refs = this.$refs?.phoneEmailFormRef?.$refs?.innerForm
          refs?.handleSubmitCallback()
        })
    },
    // 座机尾号验证
    telVerifyQuery(waybillNo, others) {
      this.$API.support
        .getSlideInvoiceTwo(waybillNo, others)
        .then((res) => {
          if (res) {
            this.resultObj = res
            if (!this.showResult) {
              this.showError = true
            } else {
              this.showError = false
            }
          }
        })
        .finally(() => {
          const refs = this.$refs?.phoneEmailFormRef?.$refs?.innerForm
          refs?.handleSubmitCallback()
        })
    },
    handleTabChange() {
      this.showError = false
    },
  },
}
