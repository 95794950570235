/*
 * @Descripttion: 全局路由配置
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-01-19 09:51:34
 */
import Support from './support'
import Account from './account'
import AboutUs from './aboutUs'
import allUrls from '@/utils/config'
import gjUtmConfig from '@/utils/gjUtm'

// 网站导航路由---依据页面访问路径
// 页面目录可依据此来进行
// 页面路由状态可依赖此进行逻辑判断
// 路由层级包括(site、lang、module、detail)
export const RoutesList = [
  // 马上寄件
  {
    // path: '/consign',
    cname: '马上寄件',
    name: 'consign',
    il8nName: 'navs.shipNow',
    gjUtm: gjUtmConfig.sfIntlOsSs2.jp,
    path: allUrls.iuopGloballoginUrl,
  },
  // 立即查单
  {
    path: '/support/querySupport/waybill',
    cname: '立即查单21',
    name: 'checkList',
    il8nName: 'navs.checkList',
  },
  // 支持
  Support,
  // 账号
  Account,
  // 联系我们
  {
    path: '/about/contact',
    cname: '联系我们',
    name: 'contactUs',
    il8nName: 'modules.about.relations',
  },
]

export const FooterRouter = [
  // 支持
  Support,
  // 账号
  Account,
  // 关于我们
  AboutUs,
]
