import { SENSOR } from '../config/const'

export const EVENT_WEB_VITAL_TYPE = {
  CLS: 'CLS',
  LCP: 'LCP',
  FID: 'FID',
  TTFB: 'TTFB',
  // INP: 'INP',
  FCP: 'FCP',
}

export const EVENT_WEB_VITAL = {
  LCP: 'IntPerformanceLCP',
  FID: 'IntPerformanceFID',
  CLS: 'IntPerformanceCLS',
  TTFB: 'IntPerformanceTTFB',
  FCP: 'IntPerformanceFCP',
}

export const EVENT_TYPE_ATTR_BASE = {
  CLS: 'int_delta_v1',
  LCP: 'int_delta_v1',
  FID: 'int_delta_v1',
  TTFB: 'int_delta_v1',
  FCP: 'int_delta_v1',
}

// 性能埋点
export const webVitalsTrack = (type, property = {}) => {
  console.log(type, 'webVitalsTrack')
  console.log(`测试性能 property`, property)
  // 公共属性里增加站点
  // eslint-disable-next-line camelcase
  const owt_site = window?.$nuxt?.$store?.state?.site
  const EVENT = EVENT_WEB_VITAL[type]
  const EVENT_TYPE_ATTR = EVENT_TYPE_ATTR_BASE[type]
  window[SENSOR].track(EVENT, {
    ...{ [EVENT_TYPE_ATTR]: property?.delta },
    ...{ channel_name: owt_site },
    ...property,
    intl_vital_value: property.value,
  })
}

/**
 * CLS
 * @param {*} property
 */
export const WebCLS = (property = {}) => {
  webVitalsTrack(EVENT_WEB_VITAL_TYPE.CLS, property)
}

/**
 * INP
 * @param {*} property
 */
// export const WebINP = (property = {}) => {
//   webVitalsTrack(EVENT_WEB_VITAL_TYPE.INP, property)
// }

/**
 * LCP
 * @param {*} property
 */
export const WebLCP = (property = {}) => {
  webVitalsTrack(EVENT_WEB_VITAL_TYPE.LCP, property)
}

/**
 * TTFB
 * @param {*} property
 */
export const WebTTFB = (property = {}) => {
  webVitalsTrack(EVENT_WEB_VITAL_TYPE.TTFB, property)
}

/**
 * FCP
 * @param {*} property
 */
export const WebFCP = (property = {}) => {
  webVitalsTrack(EVENT_WEB_VITAL_TYPE.FCP, property)
}

/**
 * FID
 * @param {*} property
 */
export const WebFID = (property = {}) => {
  webVitalsTrack(EVENT_WEB_VITAL_TYPE.FID, property)
}

function getJsResourcesTiming() {
  function checkPerformanceSupport() {
    // 检查是否存在performance对象
    if ('performance' in window && performance !== null) {
      // 检查performance对象是否包含getEntriesByType方法
      if ('getEntriesByType' in performance) {
        return true
      }
    }
    return false
  }

  if (!checkPerformanceSupport()) {
    console.error('Performance API is not supported in this browser.')
    return
  }
  // 获取所有资源类型为'resource'的性能条目
  const resources = performance.getEntriesByType('resource')

  // 过滤出所有JavaScript资源
  const jsResources = resources.filter((resource) => {
    if (
      resource.initiatorType === 'script' ||
      resource.initiatorType === 'img'
      // || resource.initiatorType === 'link'
    ) {
      const host = location.host
      if (resource.name.match(host)) {
        return true
      }
    }
    return false
  })

  // 打印每个JavaScript资源的加载时间
  jsResources.forEach((jsResource) => {
    const params = {
      resources_duration: jsResource.duration,
      // 字符串
      resources_name: jsResource.name,
      // 字符串
      resources_initiatorType: jsResource.initiatorType,
      resources_transferSize: jsResource.transferSize,
      resources_startTime: jsResource.startTime,
      resources_responseEnd: jsResource.responseEnd,
      resources_requestStart: jsResource.requestStart,
      resources_responseStart: jsResource.responseStart,
      resources_connectEnd: jsResource.connectEnd,
      resources_connectStart: jsResource.connectStart,
      resources_decodedBodySize: jsResource.decodedBodySize,
      resources_domainLookupEnd: jsResource.domainLookupEnd,
      resources_domainLookupStart: jsResource.domainLookupStart,
      resources_encodedBodySize: jsResource.encodedBodySize,
      // 字符串
      resources_entryType: jsResource.entryType,
      resources_fetchStart: jsResource.fetchStart,
      resources_navigationId: jsResource.navigationId,
      // 字符串
      resources_nextHopProtocol: jsResource.nextHopProtocol,
      resources_redirectEnd: jsResource.redirectEnd,
      resources_redirectStart: jsResource.redirectStart,
      resources_secureConnectionStart: jsResource.secureConnectionStart,
      resources_workerStart: jsResource.workerStart,
    }
    window[SENSOR].track('IntResources', params)
  })

  // 返回JavaScript资源的加载时间数组
  return jsResources
}

export function initJsResources() {
  // 在页面加载完成后执行性能数据收集
  window.addEventListener('load', () => {
    getJsResourcesTiming()
  })
}
