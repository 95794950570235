// import sensor from 'sa-sdk-javascript'
import Logger from '@sf-intl/reporter-sdk'
import options from '@/reporter/config/base'
import properties from '@/reporter/config/properties'
import plugins from '@/reporter/config/plugins'
import { initJsResources } from '@/reporter/track/webVitals.js'

export default function ({ store }, inject) {
  Logger.init({
    ...options,
    pluginsParams: {
      // API监控配置
      apiMonitor: {
        enable: true, // 启用API监控
        whiteList: [
          // API白名单
          '/api/users', // 精确匹配路径
          'api.example.com/data', // 支持带域名的完整URL
        ],
      },
      pageLeave: {
        ...plugins.PageLeave,
      },
      pageLoad: {},
    },
  })

  Logger.registerPage({
    ...{ owt_site: store?.state?.site },
    ...properties,
  })
  // 全自动增加站点属性
  Logger.quick('autoTrack', properties)
  inject('$sensor', Logger)
  initJsResources()
}
