
import { mapState } from 'vuex'
import data from './data'
import LayoutMixin from '@/mixins/layout'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import captcha from '@/utils/captcha.js'
import Result from '@/modules/templates/default/invoice/result.vue'
import Tips from '@/modules/templates/default/invoice/tip.vue'
export default {
  components: {
    SupportNav,
    SupportTop,
    Result,
    Tips,
  },
  mixins: [LayoutMixin],
  data() {
    return {
      data,
      form: this.$form.createForm(this, { name: 'waybill' }),
      showError: false,
      resultObj: {},
      loading: false,
      isBtnDisabled: true,
      isNoWaybill: true,
      isNoMobileNoLast4: true
    }
  },
  head: {
    // 可动态配置文件，可根据不同的环境配置
    script: [
      {
        src: '/tiff.js',
        type: 'text/javascript',
        charset: 'utf-8',
        async: false,
        defer: false,
      },
    ],
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
    }),
    showResult() {
      return this.resultObj?.fcInvoiceItem?.feeItems?.length
    },
  },
  created() {
    if (process.client) {
      captcha.init({ lang: this.locale, app: this })
    }
  },
  methods: {
    getSlideCaptch() {
      captcha.verify().then((res) => {
        this.getData(res)
      })
    },
    getData(opts) {
      const { waybill, mobileNoLast4 } = this.form.getFieldsValue()
      const params = {
        ...opts,
        app: 'invoice',
      }
      this.loading = true
      this.$API.support
        .getSlideInvoice(waybill, params, mobileNoLast4)
        .then((res) => {
          if (res) {
            this.resultObj = res
            // 非马来站点费用项为空
            const isNotMyNull = !res?.fcInvoiceItem?.feeItems?.length > 0
            if (isNotMyNull) {
              this.showError = true
            } else {
              this.showError = false
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleChange(e) {
      const reg = /^.{12}$|^.{15}$/
      // this.isBtnDisabled = !reg.test(e.target.value)
      this.isNoWaybill = !reg.test(e.target.value)
      this.setIsBtnDisabled()
    },
    handleBlur(e) {
      this.form.setFieldsValue({
        waybill: e?.target?.value.toUpperCase(),
      })
    },
    mobileNoLastHandleChange(e){
      // this.isNoWaybill = !reg.test(e.target.value)
      if( e.target.value ){
        this.isNoMobileNoLast4 = false
      } else {
        this.isNoMobileNoLast4 = true
      }
      this.setIsBtnDisabled()
    },
    setIsBtnDisabled(){
      if( !this.isNoWaybill && !this.isNoMobileNoLast4  ){
        this.isBtnDisabled = false
      } else {
        this.isBtnDisabled = true
      }
    }
  },
}
