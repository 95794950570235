import { render, staticRenderFns } from "./product.vue?vue&type=template&id=c4e9667c&scoped=true&"
import script from "./product.vue?vue&type=script&lang=js&"
export * from "./product.vue?vue&type=script&lang=js&"
import style0 from "./product.vue?vue&type=style&index=0&id=c4e9667c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4e9667c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Animation: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/animation/index.vue').default,AnimationInit: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/animation/init.vue').default,LinkTo: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/LinkTo.vue').default})
