import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_b5644c58 from 'nuxt_plugin_plugin_b5644c58' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_pluginserver_4bf151fc from 'nuxt_plugin_pluginserver_4bf151fc' // Source: ./plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_41e69b50 from 'nuxt_plugin_pluginclient_41e69b50' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_b0bfe650 from 'nuxt_plugin_pluginserver_b0bfe650' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_workbox_ec86f4f4 from 'nuxt_plugin_workbox_ec86f4f4' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_0eda32ce from 'nuxt_plugin_metaplugin_0eda32ce' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_058ae7e6 from 'nuxt_plugin_iconplugin_058ae7e6' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_64adba2c from 'nuxt_plugin_axios_64adba2c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_sensor_40a2c2ae from 'nuxt_plugin_sensor_40a2c2ae' // Source: ../plugins/sensor (mode: 'client')
import nuxt_plugin_cookies_d3269bca from 'nuxt_plugin_cookies_d3269bca' // Source: ../plugins/cookies (mode: 'all')
import nuxt_plugin_antdui_2b2dca90 from 'nuxt_plugin_antdui_2b2dca90' // Source: ../plugins/antd-ui (mode: 'all')
import nuxt_plugin_vant_925e8cb6 from 'nuxt_plugin_vant_925e8cb6' // Source: ../plugins/vant (mode: 'all')
import nuxt_plugin_axios_2228ef02 from 'nuxt_plugin_axios_2228ef02' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_i18n_66ff12a5 from 'nuxt_plugin_i18n_66ff12a5' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_jumpconfig_20ec878a from 'nuxt_plugin_jumpconfig_20ec878a' // Source: ../plugins/jump-config.js (mode: 'all')
import nuxt_plugin_toinject_5279d430 from 'nuxt_plugin_toinject_5279d430' // Source: ../plugins/to-inject.js (mode: 'all')
import nuxt_plugin_getcountryserver_2d86115e from 'nuxt_plugin_getcountryserver_2d86115e' // Source: ../plugins/get-country.server.js (mode: 'server')
import nuxt_plugin_resizeObserver_24aab299 from 'nuxt_plugin_resizeObserver_24aab299' // Source: ../plugins/resizeObserver.js (mode: 'all')
import nuxt_plugin_injectAxios_089af966 from 'nuxt_plugin_injectAxios_089af966' // Source: ../plugins/injectAxios.js (mode: 'all')
import nuxt_plugin_iconsvg_42165e73 from 'nuxt_plugin_iconsvg_42165e73' // Source: ../plugins/icon-svg.js (mode: 'all')
import nuxt_plugin_directive_672dc4db from 'nuxt_plugin_directive_672dc4db' // Source: ../plugins/directive (mode: 'client')
import nuxt_plugin_purifyhtml_95844b3c from 'nuxt_plugin_purifyhtml_95844b3c' // Source: ../plugins/purify-html.js (mode: 'client')
import nuxt_plugin_animate_f841372c from 'nuxt_plugin_animate_f841372c' // Source: ../plugins/animate.js (mode: 'client')
import nuxt_plugin_ump_caea4b38 from 'nuxt_plugin_ump_caea4b38' // Source: ../plugins/ump (mode: 'client')
import nuxt_plugin_map_362d360f from 'nuxt_plugin_map_362d360f' // Source: ../plugins/map.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"顺丰国际","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, user-scalable=0, initial-scale=1, minimum-scale=1,maximum-scale=1"},{"hid":"keywords","name":"keywords","content":"顺丰国际,顺丰,顺丰速运,顺丰快递,跨境物流,物流服务,国际服务"},{"hid":"description","name":"description","content":"顺丰国际"},{"hid":"apple-itunes-app","name":"apple-itunes-app","content":"app-id=1517762240"},{"hid":"facebook-domain-verification","name":"facebook-domain-verification","content":"rnsw8g1gu5synvef0u9msfkll68ein"},{}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"canonical","href":"https:\u002F\u002Fwww.sf-international.com"}],"script":[{"src":"\u002Fenv.js","async":false,"defer":false},{"src":"\u002Frem.js","type":"text\u002Fjavascript","charset":"utf-8","async":false,"defer":false}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_b5644c58 === 'function') {
    await nuxt_plugin_plugin_b5644c58(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_4bf151fc === 'function') {
    await nuxt_plugin_pluginserver_4bf151fc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_41e69b50 === 'function') {
    await nuxt_plugin_pluginclient_41e69b50(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_b0bfe650 === 'function') {
    await nuxt_plugin_pluginserver_b0bfe650(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_ec86f4f4 === 'function') {
    await nuxt_plugin_workbox_ec86f4f4(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_0eda32ce === 'function') {
    await nuxt_plugin_metaplugin_0eda32ce(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_058ae7e6 === 'function') {
    await nuxt_plugin_iconplugin_058ae7e6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_64adba2c === 'function') {
    await nuxt_plugin_axios_64adba2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sensor_40a2c2ae === 'function') {
    await nuxt_plugin_sensor_40a2c2ae(app.context, inject)
  }

  if (typeof nuxt_plugin_cookies_d3269bca === 'function') {
    await nuxt_plugin_cookies_d3269bca(app.context, inject)
  }

  if (typeof nuxt_plugin_antdui_2b2dca90 === 'function') {
    await nuxt_plugin_antdui_2b2dca90(app.context, inject)
  }

  if (typeof nuxt_plugin_vant_925e8cb6 === 'function') {
    await nuxt_plugin_vant_925e8cb6(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2228ef02 === 'function') {
    await nuxt_plugin_axios_2228ef02(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_66ff12a5 === 'function') {
    await nuxt_plugin_i18n_66ff12a5(app.context, inject)
  }

  if (typeof nuxt_plugin_jumpconfig_20ec878a === 'function') {
    await nuxt_plugin_jumpconfig_20ec878a(app.context, inject)
  }

  if (typeof nuxt_plugin_toinject_5279d430 === 'function') {
    await nuxt_plugin_toinject_5279d430(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_getcountryserver_2d86115e === 'function') {
    await nuxt_plugin_getcountryserver_2d86115e(app.context, inject)
  }

  if (typeof nuxt_plugin_resizeObserver_24aab299 === 'function') {
    await nuxt_plugin_resizeObserver_24aab299(app.context, inject)
  }

  if (typeof nuxt_plugin_injectAxios_089af966 === 'function') {
    await nuxt_plugin_injectAxios_089af966(app.context, inject)
  }

  if (typeof nuxt_plugin_iconsvg_42165e73 === 'function') {
    await nuxt_plugin_iconsvg_42165e73(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directive_672dc4db === 'function') {
    await nuxt_plugin_directive_672dc4db(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_purifyhtml_95844b3c === 'function') {
    await nuxt_plugin_purifyhtml_95844b3c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_animate_f841372c === 'function') {
    await nuxt_plugin_animate_f841372c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ump_caea4b38 === 'function') {
    await nuxt_plugin_ump_caea4b38(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_map_362d360f === 'function') {
    await nuxt_plugin_map_362d360f(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
